import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import EmailIcon from '@mui/icons-material/Email'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import ReplyIcon from '@mui/icons-material/Reply'
import VideocamIcon from '@mui/icons-material/Videocam'
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded'
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'

const userItems = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: '1. Submit Your Request',
    description:
      'Enter your details and describe your needs. We will match you with the best candidates.',
  },
  {
    icon: <PeopleIcon />,
    title: '2. Choose Your Mentor',
    description: 'Review matched profiles and select your preferred expert.',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: '3. Initiate Contact',
    description:
      'Review and finalize your request before sending it to the expert.',
  },
  {
    icon: <AccessAlarmIcon />,
    title: '4. Await Reply',
    description:
      'Receive a response via email or get invited for a detailed video call.',
  },
]

const mentorItems = [
  {
    icon: <EmailIcon />,
    title: '1. Receive Request',
    description:
      "Get an email from Vatra-Connect with a user's request for help. The email will include the specific request and the user will be CC'd.",
  },
  {
    icon: <ReplyIcon />,
    title: '2. Easy Reply',
    description:
      'Simply click the reply button. The user seeking help will automatically be the recipient.',
  },
  {
    icon: <PersonIcon />,
    title: '3. Provide Guidance',
    description:
      'Offer assistance via email or invite the user to a brief video/voice call for more in-depth support.',
  },
  {
    icon: <AccessAlarmIcon />,
    title: '4. Time-Efficient Process',
    description:
      'The entire process is designed to be quick and straightforward, respecting your valuable time.',
  },
  {
    icon: <VideocamIcon />,
    title: '5. Flexible Communication',
    description:
      'Choose between written responses or short video calls based on the complexity of the request and your preference.',
  },
]

export default function HowItWorks() {
  const [userType, ] = useState('users')
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Container maxWidth='lg' sx={{ py: { xs: 4, sm: 8 } }}>
      <Grid container spacing={isXs ? 2 : 4}>
        {(userType === 'users' ? userItems : mentorItems).map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={userType === 'mentors' ? 4 : 6}
            key={index}
          >
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '16px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardContent sx={{ flexGrow: 1, p: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    backgroundColor: 'primary.main',
                    borderRadius: '50%',
                    width: 56,
                    height: 56,
                    justifyContent: 'center',
                  }}
                >
                  {React.cloneElement(item.icon, {
                    sx: { fontSize: 32, color: 'white' },
                  })}
                </Box>
                <Typography
                  variant={isXs ? 'h6' : 'h5'}
                  component='h2'
                  sx={{ mb: 1, fontWeight: 'bold' }}
                >
                  {item.title}
                </Typography>
                <Typography variant='body2'>{item.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {userType === 'mentors' && (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography
            variant='body1'
            sx={{ fontWeight: 'bold', color: 'primary.main' }}
          >
            Remember: This process is designed to be efficient and respectful of
            your time. Your expertise can make a significant impact with minimal
            time investment.
          </Typography>
        </Box>
      )}
    </Container>
  )
}
