import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import EmailIcon from '@mui/icons-material/Email'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import ReplyIcon from '@mui/icons-material/Reply'
import VideocamIcon from '@mui/icons-material/Videocam'
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { MARGIN_TOP_MAIN_CONTENT } from '../App'

interface Item {
  icon: React.ReactElement
  title: string
  description: string
}

const userItems = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: '1. Submit Your Request',
    description:
      'Enter your details and describe your needs. We will match you with the best candidates.',
  },
  {
    icon: <PeopleIcon />,
    title: '2. Choose Your Expert',
    description: 'Review matched profiles and select your preferred expert.',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: '3. Initiate Contact',
    description:
      'Review and finalize your request before sending it to the expert.',
  },
  {
    icon: <AccessAlarmIcon />,
    title: '4. Await Reply',
    description:
      'Receive a response via email or get invited for a detailed video call.',
  },
]

const mentorItems = [
  {
    icon: <EmailIcon />,
    title: '1. Receive Request',
    description:
      "Get an email from Vatra-Connect with a user's request for help. The email will include the specific request and the user will be CC'd.",
  },
  {
    icon: <ReplyIcon />,
    title: '2. Easy Reply',
    description:
      'Simply click the reply button. The user seeking help will automatically be the recipient.',
  },
  {
    icon: <PersonIcon />,
    title: '3. Provide Guidance',
    description:
      'Offer assistance via email or invite the user to a brief video/voice call for more in-depth support.',
  },
]

const mentorInfoItems = [
  {
    icon: <AccessAlarmIcon />,
    title: 'Time-Efficient Process',
    description:
      'The entire process is designed to be quick and straightforward, respecting your valuable time.',
  },
  {
    icon: <VideocamIcon />,
    title: 'Flexible Communication',
    description:
      'Choose between written responses or short video calls based on the complexity of the request and your preference.',
  },
]

export default function HowItWorks() {
  const [userType, setUserType] = useState('users')
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const renderCard = (item: Item, index: number) => (
    <Grid item xs={12} sm={6} md={6} key={index}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <CardContent sx={{ flexGrow: 1, p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              backgroundColor: 'primary.main',
              borderRadius: '50%',
              width: 56,
              height: 56,
              justifyContent: 'center',
            }}
          >
            {React.cloneElement(item.icon, {
              sx: { fontSize: 32, color: 'white' },
            })}
          </Box>
          <Typography
            variant={isXs ? 'h6' : 'h5'}
            component='h2'
            sx={{ fontWeight: 'bold' }}
          >
            {item.title}
          </Typography>
          <Typography variant='body2'>{item.description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: MARGIN_TOP_MAIN_CONTENT,
        }}
      >
        <Box sx={{ mb: { xs: 4, sm: 6 }, textAlign: 'center' }}>
          <Typography variant={isXs ? 'h3' : 'h2'} component='h1' gutterBottom>
            How it works
          </Typography>
          <Typography
            variant={isXs ? 'h6' : 'h5'}
            color='text.secondary'
            sx={{ mb: { xs: 2, sm: 4 } }}
          >
            Easy steps, start to finish!
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <Button
              variant={userType === 'users' ? 'contained' : 'outlined'}
              onClick={() => setUserType('users')}
              sx={{ mr: 2, borderRadius: '8px', textTransform: 'none' }}
            >
              For Users
            </Button>
            <Button
              variant={userType === 'mentors' ? 'contained' : 'outlined'}
              onClick={() => setUserType('mentors')}
              sx={{ borderRadius: '8px', textTransform: 'none' }}
            >
              For Experts
            </Button>
          </Box>
        </Box>

        {userType === 'users' ? (
          <Grid container spacing={isXs ? 2 : 4}>
            <Grid container item spacing={isXs ? 2 : 4}>
              {userItems.slice(0, 2).map(renderCard)}
            </Grid>
            <Grid container item spacing={isXs ? 2 : 4}>
              {userItems.slice(2, 4).map(renderCard)}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={isXs ? 2 : 4}>
            {mentorItems.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '16px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, p: 3 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        backgroundColor: 'primary.main',
                        borderRadius: '50%',
                        width: 56,
                        height: 56,
                        justifyContent: 'center',
                      }}
                    >
                      {React.cloneElement(item.icon, {
                        sx: { fontSize: 32, color: 'white' },
                      })}
                    </Box>
                    <Typography
                      variant={isXs ? 'h6' : 'h5'}
                      component='h2'
                      sx={{ mb: 1, fontWeight: 'bold' }}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant='body2'>{item.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {userType === 'mentors' && (
          <>
            <Box sx={{ mt: 6, mb: 4, textAlign: 'center' }}>
              <Typography
                variant='h5'
                component='h3'
                sx={{ fontWeight: 'bold' }}
              >
                Additional Information
              </Typography>
            </Box>
            <Grid container spacing={isXs ? 2 : 4} justifyContent='center'>
              {mentorInfoItems.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '16px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      backgroundColor: 'background.paper',
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 2,
                          backgroundColor: 'secondary.main',
                          borderRadius: '50%',
                          width: 56,
                          height: 56,
                          justifyContent: 'center',
                        }}
                      >
                        {React.cloneElement(item.icon, {
                          sx: { fontSize: 32, color: 'white' },
                        })}
                      </Box>
                      <Typography
                        variant={isXs ? 'h6' : 'h5'}
                        component='h2'
                        sx={{ mb: 1, fontWeight: 'bold' }}
                      >
                        {item.title}
                      </Typography>
                      <Typography variant='body2'>
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {userType === 'mentors' && (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'primary.main' }}
            >
              Remember: This process is designed to be efficient and respectful
              of your time. Your expertise can make a significant impact with
              minimal time investment.
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  )
}
