import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import LanguageIcon from '@mui/icons-material/Language'
import SchoolIcon from '@mui/icons-material/School'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import { MARGIN_TOP_MAIN_CONTENT } from '../App'

const missionPoints = [
  {
    icon: <ConnectWithoutContactIcon />,
    title: 'Empowerment Through Connection',
    description:
      "We connect you with experts who have walked the path you aspire to follow. By seeing someone from a similar background succeed, you'll realize that your goals are within reach.",
  },
  {
    icon: <SchoolIcon />,
    title: 'Tailored Guidance',
    description:
      'Our experts provide personalized support on academics, career choices, and professional development. Get the right advice at the right time to steer your decisions and boost your confidence.',
  },
  {
    icon: <LanguageIcon />,
    title: 'Global Perspective',
    description:
      'Connect with professionals from your hometown who have achieved global success. Gain insights from those who understand your background and have navigated the path to international acclaim.',
  },
  {
    icon: <VolunteerActivismIcon />,
    title: 'Community of Giving',
    description:
      'Join a community where successful individuals volunteer to guide and inspire without expectation of return. Our platform ensures free access, removing barriers to your growth.',
  },
  {
    icon: <EmojiObjectsIcon />,
    title: 'Highlighting Unsung Heroes',
    description:
      'We spotlight individuals whose achievements may not make headlines but are immensely valuable. These experts, dedicated to their communities, share their knowledge and experience to help you succeed.',
  },
  {
    icon: <AccessibilityNewIcon />,
    title: 'Inclusive Growth',
    description:
      'We believe in the potential of every individual. Our platform is designed to support diverse backgrounds, learning styles, and career aspirations, ensuring everyone has the opportunity to thrive.',
  },
]

export default function OurMission() {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Box
      sx={theme => ({
        display: 'flex',
      })}
    >
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: MARGIN_TOP_MAIN_CONTENT,
        }}
      >
        <Box sx={{ mb: { xs: 4, sm: 6 }, textAlign: 'center' }}>
          <Typography variant={isXs ? 'h3' : 'h2'} component='h1' gutterBottom>
            Our Mission
          </Typography>
          <Typography
            variant={isXs ? 'h6' : 'h5'}
            color='text.secondary'
            sx={{ mb: { xs: 2, sm: 4 } }}
          >
            Empowering young individuals through connection, guidance, and
            skill-building opportunities.
          </Typography>
          <Typography variant='body1' paragraph>
            At Vatra-Connect, we bridge the gap between ambitious young
            individuals and experienced professionals. Our goal is to inspire
            and guide the next generation of leaders and innovators, paving the
            way for academic and career success.
          </Typography>
        </Box>

        <Grid container spacing={isXs ? 2 : 4}>
          {missionPoints.map((point, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '16px',
                  boxShadow: '0 4px 8px rgba(100, 100, 100, 0.3)',
                }}
              >
                <CardContent sx={{ flexGrow: 1, p: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      columnGap: '20px',
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'primary.main',
                        borderRadius: '50%',
                        minWidth: 56,
                        minHeight: 56,
                        justifyContent: 'center',
                      }}
                    >
                      {React.cloneElement(point.icon, {
                        sx: { fontSize: 32, color: 'white' },
                      })}
                    </Box>
                    <Typography
                      variant={isXs ? 'h6' : 'h5'}
                      component='h2'
                      sx={{ fontWeight: 'bold' }}
                    >
                      {point.title}
                    </Typography>
                  </Box>
                  <Typography variant='body2'>{point.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: { xs: 4, sm: 8 }, textAlign: 'center' }}>
          <Typography variant={isXs ? 'h5' : 'h4'} gutterBottom>
            Join Our Community
          </Typography>
          <Typography variant='body1' paragraph>
            Discover how our members can help and support you with their
            experiences. Whether you're seeking guidance or ready to give back,
            there's a place for you in our community.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            href='https://tally.so/r/w24BQV'
            size={isXs ? 'medium' : 'large'}
            sx={{
              fontWeight: 'bold',
              mt: 2,
              borderRadius: '8px',
              textTransform: 'none',
            }}
          >
            Apply to Join Vatra-Connect
          </Button>
        </Box>
      </Container>
    </Box>
  )
}
