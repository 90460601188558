import {
  Box,
  Button,
  Container,
  Grid,
  Link as MuiLink,
  Typography,
} from '@mui/material'
import React, { FormEvent, useState } from 'react'
import { API_BASE_URL, MARGIN_TOP_MAIN_CONTENT } from '../App'
import videoFile from './attachment/Test_Vatra.mp4'
import HowItWorks from './HowItWorks_Ins'
import LoadingAnimation from './LoadingAnimation'
import { MyCard } from './MyCard'

interface ResponseItem {
  id: string
  name?: string
  role?: string
  personalizedResponse?: string
  linkedin?: string
  github?: string
  avatarUrl?: string
  whatCanHelpWith?: string[]
}

const Hero: React.FC = () => {
  const [location, setLocation] = useState('')
  const [prompt, setPrompt] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showVideoInfo, setShowVideoInfo] = useState(false)
  const [response, setResponse] = useState<ResponseItem[]>([])
  const [errorMessage, setErrorMessage] = useState('')

  const examplePrompts = [
    {
      title: 'Academic',
      prompt: `Hello, I am currently in my senior year of high school and beginning the journey of applying to universities.

My main interests lie in [------- insert your text  -------]. I am aiming to apply to [------- insert university names or types of universities you're interested in -------], and I need guidance on [------- insert specific aspects -------].

I am facing challenges with [------- insert specific problems -------].

Any advice or insights would be greatly appreciated!`,
    },
    {
      title: 'Professional',
      prompt: `I am working in [------- insert industry -------] with [------- insert years of experience -------] experience.

I am interested in transitioning to [------- insert industry -------] and applying for opportunities for [------- insert job role -------].

I would appreciate guidance on how to strengthen my application, including skills or certifications I should focus on.
My goal is to prepare for interviews and ensure I stand out in the hiring process.

Ideally, I woudd like to connect with someone in a similar role who can share tips and personal experiences.`,
    },
    {
      title: 'Language',
      prompt: `I would like to improve my [------- insert language -------] proficiency, especially for professional or academic purposes.

I am facing difficulties in [------- describe challenges -------], such as [------- insert whether speaking, writing, understanding specific topics -------].

I am looking for advice on how to effectively improve my [------- insert language -------] skills through specific resources, courses, or techniques.

Ideally, I’d like to reach a level where I feel confident using the language in [------- insert context: work, academic, etc. -------].`,
    },
    {
      title: 'Career',
      prompt: `I am from [------- insert your location -------] and I currently work/study in [------- insert your field/industry -------].

I’m at a point where I’d like to explore new opportunities in [------- insert specific area/industry -------] or take the next step in my career.

I would greatly appreciate mentorship and advice on how to navigate this transition, including how to build connections and networks in this field.
Ideally, I’m looking to create a clear career path with actionable steps and connect with professionals who have successfully transitioned into similar roles.`,
    },
  ]

  const handleExamplePromptClick = (examplePrompt: string) => {
    setPrompt(examplePrompt)
  }

  // const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()
  //   setIsLoading(true)

  //   const payload = { location, prompt }

  //   try {
  //     // Start both the animation and the API call simultaneously
  //     const [, apiResponse] = await Promise.all([
  //       new Promise(resolve => setTimeout(resolve, 8500)), // Animation duration
  //       fetch(`${API_BASE_URL}`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Access-Control-Allow-Origin': '*',
  //         },
  //         body: JSON.stringify(payload),
  //       }).then(res => res.json()),
  //     ])

  //     setResponse(apiResponse.flat())
  //   } catch (error) {
  //     console.error('Error submitting form:', error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrorMessage('')

    if (prompt.length < 90) {
      setErrorMessage(
        'Please help us by being more specific about what you want help with!',
      )
      return
    }

    if (prompt.includes('----')) {
      setErrorMessage(
        'The question is incomplete, please modify the example prompt with your own information.',
      )
      return
    }

    setIsLoading(true)

    const payload = { location, prompt }

    try {
      // Start both the animation and the API call simultaneously
      const [, apiResponse] = await Promise.all([
        new Promise(resolve => setTimeout(resolve, 8500)), // Animation duration
        fetch(`${API_BASE_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(payload),
        }).then(res => res.json()),
      ])

      setResponse(apiResponse.flat())
    } catch (error) {
      console.error('Error submitting form:', error)
      setErrorMessage(
        'An error occurred while processing your request. Please try again.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      id='hero'
      sx={{
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif',
        position: 'relative',
        marginBottom: '8rem',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: MARGIN_TOP_MAIN_CONTENT,
        }}
      >
        <Typography
          variant='h2'
          sx={{
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            lineHeight: '1.2',
            fontFamily: '"Inter"',
            marginBottom: '2rem',
          }}
        >
          Get Help and Connect Instantly with{' '}
          <Box
            component='span'
            sx={{
              color: 'primary.main',

              zIndex: '5',
              gridColumnGap: '.25rem',
              gridRowGap: '.25rem',
              flexFlow: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex',
              position: 'relative',
            }}
          >
            Global Experts
          </Box>{' '}
          Who Share{' '}
          <Box component='span' sx={{ color: 'primary.main' }}>
            Your Roots
          </Box>
        </Typography>
        <Typography
          variant='body1'
          sx={{
            fontSize: '1.2rem',
            marginBottom: '50px',
            maxWidth: '600px',
            color: '#555',
          }}
        >
          Find your local ambassador and reach out to them for help. They are
          waiting to help you.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              maxWidth: '1000px',
            }}
          >
            <input
              type='text'
              value={location}
              onChange={e => setLocation(e.target.value)}
              placeholder='Where are you from? (City, Country)'
              required
              style={{
                width: '100%',
                padding: '15px',
                marginBottom: '15px',
                borderRadius: '15px',
                border: '1px solid rgba(0,0,255,0.1)',
                boxShadow: '0 2px 5px rgba(0,0,255,0.1)',
                fontFamily: 'Inter, sans-serif',
                fontSize: '17px',
              }}
            />
            <textarea
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
              placeholder='Tell us what you need help with and include as much context as possible, such as your study or work plans. The more context you provide, the better we can help you.'
              required
              style={{
                width: '100%',
                padding: '15px',
                paddingBottom: '50px',
                borderRadius: '15px',
                border: '1px solid rgba(0,0,255,0.1)',
                boxShadow: '0 2px 5px rgba(0,0,255,0.1)',
                minHeight: '300px',
                resize: 'vertical',
                marginBottom: '15px',
                fontFamily: 'Inter, sans-serif',
                fontSize: '17px',
              }}
            />
            {errorMessage && (
              <Typography
                variant='body1'
                sx={{
                  color: 'error.main',
                  marginBottom: '1rem',
                  fontWeight: 'bold',
                }}
              >
                {errorMessage}
              </Typography>
            )}
            <Box
              sx={{
                width: '100%',
                marginBottom: '15px',
                display: 'flex',
                justifyContent: 'center',
                gap: '15px',
                flexWrap: 'wrap',
              }}
            >
              {examplePrompts.map((example, index) => (
                <Button
                  key={index}
                  onClick={() => handleExamplePromptClick(example.prompt)}
                  sx={{
                    padding: '8px 16px',
                    fontSize: '0.9rem',
                    color: 'primary',
                    border: '1px solid #3b82f6',
                    borderRadius: '20px',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#bfdbfe',
                      transform: 'translateY(-2px)',
                    },
                  }}
                >
                  <Typography
                    component='span'
                    variant='body1'
                    sx={{ fontWeight: 'bold', display: 'block' }}
                  >
                    Example {index + 1}
                  </Typography>
                  <Typography
                    component='span'
                    variant='body2'
                    sx={{
                      fontWeight: 'normal',
                      display: 'block',
                      fontSize: '0.6rem',
                    }}
                  >
                    {example.title}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Typography
              variant='body2'
              sx={{
                fontSize: '0.9rem',
                color: '#555',
                marginBottom: '12px',
                marginTop: '8px',
              }}
            >
              Unsure what to do? Click{' '}
              <MuiLink
                onClick={e => {
                  e.preventDefault()
                  setShowInfo(true)
                }}
                sx={{
                  cursor: 'pointer',
                  color: '#fff',
                  backgroundColor: 'primary.main',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '4px',
                  verticalAlign: 'middle',
                  fontSize: '12px',
                  lineHeight: '1',
                }}
              >
                i
              </MuiLink>{' '}
              for instructions or{' '}
              <MuiLink
                onClick={e => {
                  e.preventDefault()
                  setShowVideoInfo(true)
                }}
                sx={{
                  cursor: 'pointer',
                  color: '#fff',
                  backgroundColor: 'primary.main',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '4px',
                  verticalAlign: 'middle',
                  fontSize: '12px',
                  lineHeight: '1',
                }}
              >
                i
              </MuiLink>{' '}
              for a video.
            </Typography>
            <Button
              type='submit'
              disabled={isLoading}
              sx={{
                marginTop: '10px',
                padding: '10px 20px',
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'primary.main',
                borderRadius: '15px',
                cursor: 'pointer',
                boxShadow: '0 2px 5px rgba(0,123,255,0.2)',
              }}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </Box>
        </form>

        <Box>
          {response.length > 0 && (
            <Grid
              container
              spacing={2}
              justifyContent='center'
              style={{ marginTop: '10px' }}
            >
              {response && typeof response[0] === 'string' ? (
                <Typography
                  variant='h6'
                  color='error'
                  style={{ marginTop: '20px' }}
                >
                  {response[0]}
                </Typography>
              ) : Array.isArray(response) ? (
                <Grid
                  container
                  spacing={2}
                  justifyContent='center'
                  style={{ marginTop: '20px' }}
                >
                  {response.map((resp, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={response.length === 1 ? 12 : 6}
                      lg={response.length === 1 ? 6 : 4}
                      key={index}
                    >
                      <MyCard
                        avatarUrl={require(
                          `./pictures_members/${resp.id}.jpeg`,
                        )}
                        id_Mentor={resp.id}
                        name={resp.name!}
                        role={resp.role!}
                        personalResponse={resp.personalizedResponse!}
                        githubUrl={resp.github!}
                        linkedinUrl={resp.linkedin!}
                        chips={resp
                          .whatCanHelpWith!.slice(0, 4)
                          .map((item, index) => ({
                            color:
                              index === 0 || index === 3
                                ? 'secondary'
                                : index === 1
                                  ? 'primary'
                                  : 'warning',
                            label: item,
                          }))}
                        prompt={prompt}
                        // nameSender={'firstName'}
                        // locationSender={location}
                        // emailSender={'emailSender'}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : null}
            </Grid>
          )}
        </Box>
        {showInfo && (
          <div
            onClick={() => setShowInfo(false)}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              border: '1px solid black',
              boxShadow: '0 0 10px rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
            }}
          >
            <Box
              onClick={e => e.stopPropagation()} // Stop propagation to prevent closing when clicking inside the pop-up
              sx={{
                backgroundColor: 'white',
                border: '1px solid primary.main',
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: { xs: '75%', md: '60%' },
                maxHeight: { xs: '75%', md: '60%' },
                overflow: 'auto',
              }}
            >
              <HowItWorks />

              <Button
                onClick={() => setShowInfo(false)}
                sx={{
                  padding: '10px 20px',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  border: 'none',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  marginTop: '25px',
                  '&:hover': { backgroundColor: '#6a32c4' },
                }}
              >
                Close
              </Button>
            </Box>
          </div>
        )}
        {showVideoInfo && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              border: '1px solid black',
              boxShadow: '0 0 10px rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
            }}
            onClick={() => setShowVideoInfo(false)} // Close pop-up on background click
          >
            <Box
              sx={{
                backgroundColor: 'white',
                border: '1px solid primary.main',
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: { xs: '75%', md: '60%' },
                maxHeight: { xs: '75%', md: '60%' },
                overflow: 'auto',
              }}
              onClick={e => e.stopPropagation()} // Prevent pop-up from closing when clicking inside the box
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: '16px',
                  backgroundColor: 'white',
                  borderRadius: '16px',
                  boxShadow: 0,
                }}
              >
                <video
                  loop
                  autoPlay
                  muted
                  playsInline
                  style={{
                    width: '80%',
                    height: '90%',
                    borderRadius: '8px',
                  }}
                >
                  <source src={videoFile} type='video/mp4' />
                </video>
              </Box>
            </Box>
          </div>
        )}
      </Container>
      {isLoading && <LoadingAnimation />}
    </Box>
  )
}

export default Hero
