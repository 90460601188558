import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import React from 'react'
import { API_BASE_URL } from '../App'

interface MyCardProps {
  avatarUrl: string
  id_Mentor: string
  name: string
  role: string
  personalResponse: string
  githubUrl: string
  linkedinUrl: string
  chips: Array<{ color: 'primary' | 'secondary' | 'warning'; label: string }>
  prompt: string
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#f0f8ff',
  boxShadow: 24,
  borderRadius: '15px',
  p: 4,
  width: { xs: '90%', sm: '70%', md: '50%', lg: '30%' },
  maxWidth: '1000px',
  maxHeight: '90vh',
  overflowY: 'auto',
}

const inputStyle = {
  width: '100%',
  padding: '15px',
  marginBottom: '15px',
  borderRadius: '15px',
  border: '1px solid rgba(0,0,255,0.1)',
  boxShadow: '0 2px 5px rgba(0,0,255,0.1)',
  fontFamily: 'Arial, sans-serif',
  fontSize: '17px',
}

export function MyCard({
  avatarUrl,
  id_Mentor,
  name,
  role,
  personalResponse,
  githubUrl,
  linkedinUrl,
  chips,
  prompt,
}: MyCardProps) {
  const [isModalOpen, setOpen] = React.useState(false)
  const [isSending, setIsSending] = React.useState(false)
  const [emailStatus, setEmailStatus] = React.useState({
    sent: false,
    message: '',
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [emailExtra, setEmailExtra] = React.useState('')
  const [nameSender, setNameSender] = React.useState('')
  const [emailSender, setEmailSender] = React.useState('')

  const formattedPrompt = emailExtra
    ? `${prompt}<br><br><strong>Email Additional Info:</strong><br>${emailExtra}`
    : prompt

  const handleSendEmail = async () => {
    setIsSending(true)
    const emailData = {
      id_Mentor: id_Mentor,
      nameMentor: name,
      cc_addresses: emailSender,
      prompt: formattedPrompt,
      // isHtml: true,
      nameSender,
      emailSender,
    }

    try {
      const response = await fetch(`${API_BASE_URL}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      })
      const responseData = await response.json()
      setIsSending(false)
      if (response.ok) {
        setEmailStatus({
          sent: true,
          message: 'Email sent successfully! Check your email including spam!',
        })
      } else {
        setEmailStatus({
          sent: false,
          message: responseData.error || 'Failed to send email',
        })
      }
    } catch (error) {
      setIsSending(false)
      setEmailStatus({
        sent: false,
        message: 'Network error, please try again',
      })
    }
  }

  return (
    <Card
      sx={{ borderRadius: '15px', boxShadow: '0 2px 5px rgba(0,0,255,0.1)' }}
    >
      <CardContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <Avatar
              sx={{ width: 70, height: 70, borderRadius: '15px' }}
              src={avatarUrl}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h5'
              component='div'
              sx={{ fontWeight: 600, textAlign: 'left' }}
            >
              {name}
            </Typography>
            <Typography
              variant='subtitle1'
              sx={{
                fontStyle: 'italic',
                color: 'grey',
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              {role}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' align='left'>
              {personalResponse}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              gap: '6px',
              flexWrap: 'wrap',
              paddingTop: '8px',
            }}
          >
            {chips.map((chip, index) => (
              <Chip key={index} color={chip.color} label={chip.label} />
            ))}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          padding: '0 16px 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Button
            onClick={handleOpen}
            variant='outlined'
            style={{ flexShrink: 0, fontWeight: 'bold', borderRadius: '15px' }}
            size='small'
          >
            Send an Email
          </Button>
          <Modal
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={modalStyle}>
              <Typography
                variant='h4'
                component='h2'
                sx={{ mb: 2, fontWeight: 'bold' }}
              >
                Your prompt to {name}
              </Typography>
              <Typography variant='body1' sx={{ mb: 2 }}>
                (scrollable)
              </Typography>
              <Box
                sx={{
                  ...inputStyle,
                  mb: 3,
                  bgcolor: 'white',
                  minHeight: '100px',
                  overflowY: 'auto',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                }}
              >
                {prompt}
              </Box>
              <Typography
                variant='h5'
                component='h3'
                sx={{ mb: 2, fontWeight: 'bold' }}
              >
                To contact the person, we need your info
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <input
                  type='text'
                  value={nameSender}
                  onChange={e => setNameSender(e.target.value)}
                  placeholder='Name & Lastname'
                  style={inputStyle}
                />
                <input
                  type='email'
                  value={emailSender}
                  onChange={e => setEmailSender(e.target.value)}
                  placeholder='Email'
                  style={inputStyle}
                />
              </Box>
              <Typography
                variant='h5'
                component='h3'
                sx={{ mb: 2, fontWeight: 'bold' }}
              >
                Do you want to add anything before sending the email?
              </Typography>
              <textarea
                value={emailExtra}
                onChange={e => setEmailExtra(e.target.value)}
                placeholder='Additional text'
                style={{
                  ...inputStyle,
                  minHeight: '100px',
                  resize: 'vertical',
                }}
              />
              <LoadingButton
                loading={isSending}
                onClick={handleSendEmail}
                variant='contained'
                size='large'
                sx={{
                  mt: 2,
                  fontWeight: 'bold',
                  bgcolor: '#0056b3',
                  '&:hover': { bgcolor: '#003d82' },
                  borderRadius: '15px',
                  padding: '10px 20px',
                }}
                disabled={emailStatus.sent}
              >
                SEND THE EMAIL. FOR REAL.
              </LoadingButton>
              {emailStatus.sent && (
                <Typography
                  variant='body1'
                  sx={{ mt: 2, textAlign: 'center', color: 'green' }}
                >
                  {emailStatus.message}
                </Typography>
              )}
            </Box>
          </Modal>
        </div>
        <div>
          <a
            href={githubUrl}
            target='_blank'
            rel='noopener noreferrer'
            aria-label='GitHub'
          >
            <IconButton color='inherit' sx={{ padding: '2px', color: 'black' }}>
              <GitHubIcon />
            </IconButton>
          </a>
          <a
            href={linkedinUrl}
            target='_blank'
            rel='noopener noreferrer'
            aria-label='LinkedIn'
          >
            <IconButton
              color='inherit'
              sx={{ padding: '2px', color: '#0077B5' }}
            >
              <LinkedInIcon />
            </IconButton>
          </a>
        </div>
      </CardActions>
    </Card>
  )
}
export default MyCard
