import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AppAppBar from './components/AppAppBar'
import FAQ from './components/FAQ'
import Footer from './components/Footer'
import Hero from './components/Hero_t'
import HowItWorks from './components/HowItWorks'
import LogoCollection from './components/LogoCollection'
import OurMission from './components/OurMission'
import getLPTheme, { brand } from './getLPTheme'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const MARGIN_TOP_MAIN_CONTENT = '10rem'

export default function LandingPage() {
  // This theme will always be in light mode now
  const LPtheme = createTheme(getLPTheme('light'))

  return (
    <Box
      sx={theme => ({
        backgroundImage: `linear-gradient(180deg, ${brand[100]}, #FFF)`,
        backgroundSize: '100% 55%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Router>
        <ThemeProvider theme={LPtheme}>
          <CssBaseline />
          <AppAppBar />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100vh',
              margin: '0 auto',
            }}
          >
            <Box>
              <Routes>
                <Route path='/our-mission' element={<OurMission />} />
                <Route path='/how-it-works' element={<HowItWorks />} />
                <Route path='/faq' element={<FAQ />} />
                <Route
                  path='/'
                  element={
                    <>
                      <Hero />
                      <Box sx={{ bgcolor: 'transparent' }}>
                        <LogoCollection />
                        {/* <HowItWorks /> */}
                        {/* <OurMission /> */}
                        {/* <FAQ /> */}
                      </Box>
                    </>
                  }
                />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </ThemeProvider>
      </Router>
    </Box>
  )
}
