import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Marquee from 'react-fast-marquee'

const darkLogos = [
  'https://upload.wikimedia.org/wikipedia/commons/f/f4/BMW_logo_%28gray%29.svg',
  'https://upload.wikimedia.org/wikipedia/sco/2/21/Nvidia_logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/b/bb/Infineon-Logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/c/c8/Logo_of_the_Technical_University_of_Munich.svg',
  'https://upload.wikimedia.org/wikipedia/commons/3/32/CGI_logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/0/05/PricewaterhouseCoopers_Logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/e/ea/ETH_Z%C3%BCrich_Logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/5/5f/Siemens-logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/9/92/Audi-Logo_2016.svg',
  'https://upload.wikimedia.org/wikipedia/commons/6/6d/Volkswagen_logo_2019.svg',
  'https://upload.wikimedia.org/wikipedia/commons/2/2e/Logo_der_Technischen_Universit%C3%A4t_Berlin.svg',
  'https://upload.wikimedia.org/wikipedia/commons/b/b4/Celonis_Logo.png',
]

const LogoCollection = () => {
  return (
    <Box
      sx={{
        overflowX: 'hidden',
        py: 1,
        textAlign: 'center',
        width: '50%',
        margin: 'auto',
        minHeight: { xs: '40vh', sm: '40vh', md: '20vh' },
      }}
    >
      <Typography
        component='p'
        variant='subtitle1'
        color='text.secondary'
        sx={{
          mb: 4,
          fontWeight: 'bold',
          fontSize: 'x-large',
        }}
      >
        Our community members work for:
      </Typography>
      <Marquee gradient gradientWidth={40} speed={105} pauseOnHover={true}>
        {darkLogos.map((logo, index) => (
          <img
            src={logo}
            alt={`Logo number ${index + 1}`}
            key={index}
            style={{ width: 120, height: 90, opacity: 0.7, marginRight: 62 }}
          />
        ))}
      </Marquee>
    </Box>
  )
}

export default LogoCollection
