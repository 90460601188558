import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMediaQuery, useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { MARGIN_TOP_MAIN_CONTENT } from '../App'

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        id='faq'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: MARGIN_TOP_MAIN_CONTENT,
        }}
      >
        <Typography
          component='h1'
          variant={isXs ? 'h3' : 'h2'}
          color='text.primary'
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
          gutterBottom
        >
          Frequently asked questions
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1d-content'
              id='panel1d-header'
            >
              <Typography component='h3' variant='subtitle2'>
                How do I contact customer support in case of issues?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                You can reach our customer support team by emailing
                <Link href='mailto:support@vatra-connect.org'>
                  {' '}
                  support@vatra-connect.org{' '}
                </Link>
                . We&apos;re here to assist you promptly.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2d-content'
              id='panel2d-header'
            >
              <Typography component='h3' variant='subtitle2'>
                I want to help or have questions about the initiative, who do I
                contact?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                You can press the "Reach Out" button and write "I want to
                contact the cofounders!" to get in touch with one of the
                founders. Feel free to ask any questions, give feedback, or
                share your remarks. We welcome all forms of communication!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel3d-content'
              id='panel3d-header'
            >
              <Typography component='h3' variant='subtitle2'>
                Is this a mentor-finding platform?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                Not exactly. We aim to match you with individuals from similar
                backgrounds (specifically from your hometown) who have walked
                similar paths in life. This isn’t a platform to find mentors but
                rather to make the initial connection with people willing to
                help with your specific requests. The more detailed your
                request, the better we can assist and connect you with the right
                person.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel4d-content'
              id='panel4d-header'
            >
              <Typography component='h3' variant='subtitle2'>
                In how many places is this platform working right now?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                Currently, we are iterating and improving our services. Our
                network primarily includes people and hometowns from North
                Macedonia, Kosovo, and Albania. We are actively working towards
                expanding to more cities.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel5d-content'
              id='panel5d-header'
            >
              <Typography component='h3' variant='subtitle2'>
                If I am a member and I want to unsubscribe, how do I do that?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                To unsubscribe and to be not contacted anymore, you can fill out
                this{' '}
                <Link href='https://tally.so/r/mD1KBq' target='_blank'>
                  form
                </Link>{' '}
                and all your information will be deleted.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  )
}
