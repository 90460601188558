import { useEffect, useState } from 'react'

const LoadingAnimation = () => {
  const [text1, setText1] = useState('')
  const [text2, setText2] = useState('')

  // Array of text options

  useEffect(() => {
    const textOptions = [
      {
        fullText1:
          "You may not have it all figured out yet... but you don't have to do it alone.",
        fullText2:
          'Lean on our experience to guide you through the journey ahead!',
      },
      {
        fullText1:
          'Success isn’t given, it’s earned. And we’re here to help you earn it!',
        fullText2:
          'Let our experience inspire you to reach your full potential!',
      },
      {
        fullText1:
          "Every great journey starts with a single step, and we're here for each one.",
        fullText2: "Together, let's turn your ambition into action!",
      },
      {
        fullText1:
          "We can't offer you money ... because we don't have any.... yet.",
        fullText2:
          'But we can offer you our experience for you to get inspired!',
      },
    ]
    // Randomly select one of the text options
    const randomIndex = Math.floor(Math.random() * textOptions.length)
    const selectedText = textOptions[randomIndex]

    let index1 = 0
    let index2 = 0

    // Typing animation for the selected text
    const intervalId = setInterval(() => {
      if (index1 < selectedText.fullText1.length) {
        setText1(_ => selectedText.fullText1.slice(0, index1 + 1))
        index1++
      } else if (index2 < selectedText.fullText2.length) {
        setText2(_ => selectedText.fullText2.slice(0, index2 + 1))
        index2++
      } else {
        clearInterval(intervalId)
      }
    }, 50) // Adjusted timing for faster typing

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <svg width='200' height='100' viewBox='0 0 200 100'>
        <path
          d='M10 50 Q50 10, 90 50 T170 50'
          fill='none'
          stroke='#4285f4'
          strokeWidth='3'
        >
          <animate
            attributeName='d'
            values='
              M10 50 Q50 10, 90 50 T170 50;
              M10 50 Q50 90, 90 50 T170 50;
              M10 50 Q50 10, 90 50 T170 50
            '
            dur='2s'
            repeatCount='indefinite'
          />
        </path>
      </svg>
      <div
        style={{
          marginTop: '20px',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
          maxWidth: '80%',
          textAlign: 'center',
          height: '3.2rem', // Ensuring fixed height for the container
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: '1.6rem', marginBottom: '10px' }}>{text1}</p>
        <p style={{ fontSize: '1.5rem', marginTop: '10px' }}>{text2}</p>
      </div>
    </div>
  )
}

export default LoadingAnimation
