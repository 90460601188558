import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LogoTest from './attachment/VC_logo4.svg'

const logoStyle = {
  width: '100px',
  height: 'auto',
  cursor: 'pointer',
}

function AppAppBar() {
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const navigate = useNavigate()

  const scrollToSection = (sectionId: string) => {
    // Navigate to the home page ('/') first
    navigate('/')
    // Wait for the navigation to complete, then scroll to the section
    setTimeout(() => {
      const sectionElement = document.getElementById(sectionId)
      const offset = 30 // Adjust the offset for your fixed header or any other adjustments
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset
        window.scrollTo({ top: targetScroll, behavior: 'smooth' })
      }
    }, 100)
  }

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 4,
        }}
      >
        <Container maxWidth='lg'>
          <Toolbar
            variant='regular'
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '15px',
              bgcolor: 'rgba(255, 255, 255, 0.5)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)',
            })}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', // Space between logo and menu
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Link to='/' style={{ textDecoration: 'none' }}>
                  <img
                    src={LogoTest}
                    style={{
                      ...logoStyle,
                      width: '65px',
                      height: 'auto',
                      marginLeft: '10px',
                      marginRight: '0px',
                    }}
                    alt='logo of sitemark'
                  />
                </Link>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexGrow: 1,
                  marginRight: '100px',
                }}
              >
                <Box
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '30px', // Added gap to increase the distance between the items
                  }}
                >
                  <MenuItem
                    component={Link}
                    to='/our-mission'
                    sx={{
                      py: '6px',
                      px: '12px',
                      borderRadius: '15px',
                    }}
                  >
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                    >
                      Our Mission
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/how-it-works'
                    onClick={() => scrollToSection('features')}
                    sx={{ py: '6px', px: '12px', borderRadius: '15px' }}
                  >
                    <Typography
                      variant='body1'
                      // color='primary.main'
                      color='text.secondary'
                      sx={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                    >
                      How It Works
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/faq'
                    onClick={() => scrollToSection('faq')}
                    sx={{ py: '6px', px: '12px', borderRadius: '15px' }}
                  >
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                    >
                      FAQ
                    </Typography>
                  </MenuItem>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant='text'
                color='primary'
                aria-label='menu'
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  />
                  <MenuItem>
                    <Button
                      color='primary'
                      variant='outlined'
                      component={Link}
                      to='/our-mission'
                      sx={{ width: '100%', borderRadius: '16px' }}
                    >
                      Our Mission
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      color='primary'
                      variant='outlined'
                      component={Link}
                      to='/how-it-works'
                      sx={{ width: '100%', borderRadius: '16px' }}
                    >
                      How It Works
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      color='primary'
                      variant='outlined'
                      component={Link}
                      to='/faq'
                      sx={{ width: '100%', borderRadius: '16px' }}
                    >
                      FAQ
                    </Button>
                  </MenuItem>
                  <Divider />
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

export default AppAppBar
