import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import TermsDialog from './TermsDialog'

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' mt={1}>
      {'Copyright © '}
      <Link href='/' color='inherit'>
        Vatra-Connect&nbsp;
      </Link>
      {new Date().getFullYear()}
    </Typography>
  )
}

export default function Footer() {
  const [openTerms, setOpenTerms] = React.useState(false)

  const handleOpenTerms = () => setOpenTerms(true)
  const handleCloseTerms = () => setOpenTerms(false)

  return (
    <Container
      sx={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 2, sm: 4 },
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
      }}
    >
      <Box
        sx={{
          justifyContent: 'space-between',
          pt: { xs: 2, sm: 4 },
          width: '100%',
        }}
      >
        <div>
          <Link color='text.secondary' href='#' onClick={handleOpenTerms}>
            Terms & Privacy
          </Link>
          <Copyright />
        </div>
      </Box>
      <TermsDialog open={openTerms} onClose={handleCloseTerms} />
    </Container>
  )
}
