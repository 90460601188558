import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import React from 'react'

interface TermsDialogProps {
  open: boolean
  onClose: () => void
}

const TermsDialog: React.FC<TermsDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant='h6' gutterBottom>
            Introduction
          </Typography>
          Welcome to Vatra-Connect, a digital platform dedicated to bridging the
          gap between the youth of cities and rural areas with professionals
          from diverse academic and professional backgrounds. Our mission is to
          foster an intellectual and academic environment where experienced
          mentors provide guidance and support.
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            Scope of Services
          </Typography>
          Vatra-Connect aims to offer young individuals and those in need
          exposure to international markets and guidance through:
          <ul>
            <li>
              <strong>Volunteer Platform:</strong> Community members, especially
              those with international experience, volunteer their time and
              expertise.
            </li>
            <li>
              <strong>Spirit of Giving Back:</strong> Successful individuals
              from the community provide guidance without expecting anything in
              return.
            </li>
            <li>
              <strong>Non-Commercial Setting:</strong> Ensuring interactions
              occur in a voluntary and non-commercial setting, eliminating cost
              barriers.
            </li>
          </ul>
          Our platform facilitates access to a network of experienced
          individuals to enhance the start of one’s academic or professional
          path, including:
          <ul>
            <li>Guidance on choosing a course of study.</li>
            <li>Brainstorming ideas.</li>
            <li>Preparing for interviews.</li>
            <li>
              Seeking scholarships and other academic or career-related support.
            </li>
          </ul>
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            Matching Process
          </Typography>
          Our system matches users with mentors based on their specific needs:
          <ol>
            <li>
              Users visit our website and enter their details (name, email,
              location).
            </li>
            <li>They describe what they need assistance with in a prompt.</li>
            <li>
              Upon submission, we process their request and propose three
              suitable mentors from our network.
            </li>
            <li>
              Users can review these profiles and select one, two, or all three
              mentors.
            </li>
            <li>
              They can contact the chosen mentor(s) by sending a request, and
              the mentor will respond accordingly.
            </li>
          </ol>
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            Use Case
          </Typography>
          <ol>
            <li>The user visits the Vatra-Connect website.</li>
            <li>
              They fill out their name, last name, location, email, and a
              detailed prompt describing their needs.
            </li>
            <li>
              The system processes the request and provides three mentor
              options.
            </li>
            <li>
              The user selects a mentor and may add additional details before
              sending the request.
            </li>
            <li>
              The chosen mentor receives the request and contacts the user to
              provide the requested guidance.
            </li>
          </ol>
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            Data Handling and Privacy
          </Typography>
          For data analytics and matchmaking improvement:
          <ul>
            <li>
              <strong>Stored Data:</strong> We only store the location and
              prompt.
            </li>
            <li>
              <strong>Processed Data:</strong> Names, last names, and emails are
              processed solely for setting up connections between users and
              mentors.
            </li>
            <li>
              <strong>Non-Stored Data:</strong> Names, last names, and emails
              are not stored after the email service processes the request.
            </li>
          </ul>
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            User Interaction and Limitations
          </Typography>
          <ul>
            <li>
              Upon selecting a mentor and sending an email request, the "Send
              Email" button will be disabled to prevent spamming.
            </li>
            <li>
              Users are encouraged to provide detailed and accurate information
              to ensure the best possible match.
            </li>
          </ul>
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            Community and Inspirational Aspect
          </Typography>
          Our platform is designed to inspire and motivate individuals by
          connecting them with successful professionals from similar
          backgrounds. The sentiment,{' '}
          <strong>
            "If they could achieve so much, why shouldn't I? What's stopping
            me?"
          </strong>{' '}
          underpins our mission to empower users by leveraging success stories
          within our network.
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            Conclusion
          </Typography>
          Vatra-Connect is continuously evolving to provide the most accurate
          and relevant matches. While we strive to offer the best service, there
          may be occasional limitations. We appreciate your understanding and
          are committed to improving your experience. By using Vatra-Connect,
          you agree to these terms and conditions, and you consent to the
          processing of your personal data as outlined above. If you have any
          questions or concerns, please contact us for further assistance.
          <Typography variant='h6' gutterBottom style={{ marginTop: '16px' }}>
            Privacy Policy
          </Typography>
          For detailed information on how we handle and protect your data,
          please refer to our Privacy Policy. By clicking the "I accept the
          terms and conditions" checkbox, you acknowledge that you have read,
          understood, and agreed to our terms and conditions, including our
          Privacy Policy and Data Protection guidelines.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TermsDialog
